import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Header from '../components/Header'
import Content, { HTMLContent } from '../components/Content'
import DemoSidebar from '../components/DemoSidebar'
import Documentation from '../components/Documentation'
import Footer from '../components/Footer'
import RelatedContent from '../components/RelatedContent'


export const DemoPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <section className="section section--gradient font-inter bg_white">
      <Header overrideDarkTheme={true}/>
      <div className="pt-4">
      <main className="flex-grow">

          <section>
          <div className="max-w-6xl mx-auto px-2 sm:px-2">
              <div className="pt-32 pb-12 md:pt-40 md:pb-20">

              {/* Main content */}
              <div className="md:flex md:justify-between" data-sticky-container>

                  <DemoSidebar />

                  <div className="md:flex-grow  font-inter">

                    {/* <Header /> */}
                    <div className="text-lg text-gray-600">
                      <h1 className="h1 text-3xl text-gray-900 mb-4 font-bold">Demos</h1>

                      <p className="mb-8">
                        Welcome! In this section, we've provided a variety of demos, showcasing the varying 
                        applications of Lightbox.js as well as plenty of examples of the library in action. 
                        To get started, simply click on a link below!
                      </p>

                      <ul className="list-disc mb-8 ml-8">
                        <li className="text-underline text-blue-700"> <b><Link to="/demo/PhotoShowcaseDemo">Photo Showcase</Link></b> </li>
                        <li className="text-underline text-blue-700"> <b><Link to="/demo/ProductListingDemo">Café Example</Link></b> </li>
                        <li className="text-underline text-blue-700"> <b><Link to="/demo/FullScreenImages">Full Screen Images</Link></b> </li>
                        <li className="text-underline text-blue-700"> <b><Link to="/demo/ProductDemo">Product Demo</Link></b> </li>
                        <li className="text-underline text-blue-700"> <b><Link to="/demo/ProductPageDemo">E-commerce Page</Link></b> </li>

                      </ul>

    </div>

    {/* Related content */}
      <RelatedContent />

    {/* <Feedback /> */}

  </div>

              </div>

              </div>
          </div>
          </section>

          </main>
      </div>

      <Footer overrideDarkTheme={true} />

  </section>
  )
}

DemoPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const DemoPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <DemoPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

DemoPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default DemoPage

export const demoPageQuery = graphql`
  query DemoPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
